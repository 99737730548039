/********************************************************************
*
*	agrarwetter.app - style
*	Copyright 2019 agrarwetter.app
*
*	by Patrick Bredemeier (bredemeier@kaack-terminhandel.de)
*
********************************************************************/
body {
    color:#31535B;
    /* background-color:rgba(0, 0, 0, 0.02); */
    /* background-color: #f3f5f8; */

    /* background-color: #F4F4F4; */
}
::selection {
	background-color: #79AC37;
	color: #fff;
	text-shadow: none;
}
img {
    max-width: 100%;
    height: auto;
}

/* =============================================================================
 * bootstrap
 * ========================================================================== */
/* @media (min-width:1540px) {
	.container {
		max-width: 1480px
	}
} */
/* @media (min-width:1540px) {
	.container {
		max-width: 1380px
	}
} */

/* =============================================================================
 * header
 * ========================================================================== */
.app-header {
    padding: 0;
    /* box-shadow: 0 2px 13px 0 rgba(230,230,230,.5); */
    position: relative;
}
.app-header .logo {
    width: 300px;
    padding: 20px 0 10px 0;
}
@media only screen and (max-width: 991px) {
    .app-header .logo {
        width: 200px;
    }
}
.app-header .header-bar {
    /* background: #F7F8F9; */
    /* background:#fff; */
    /* box-shadow: rgba(0,0,0,.2) 0 0 5px; */
}
.app-header .header-bar .container {
    display: flex;
    align-items: center;
}
/* .app-header .headroom--pinned .header-bar {
  box-shadow: rgba(0,0,0,.2) 0 0 5px;
  background: #fff;
}
.app-header .headroom--pinned .logo {
    width: 200px;
} */


/* =============================================================================
 * elements
 * ========================================================================== */
.flexStretch {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: block;
}
.d-flex {
    display:flex;
}
.jc-c {
    justify-content: center;
}
.ai-c {
    align-items: center;
}
/* .transition {
    transition: 0.5s;
} */

/* =============================================================================
 * typography
 * ========================================================================== */
h1 {
    font-size: 26px;
}
h2 {
    font-size: 22px;
}
@media only screen and (max-width: 991px) {
    h1 {
        font-size: 22px;
    }
    h2 {
        font-size: 20px;
    }
}

/* =============================================================================
 * autosuggest
 * ========================================================================== */
.react-autosuggest__suggestions-container ul {
    list-style: none;
    padding:0;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid #ccc;
    color: #31535B;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.25);
    position: absolute;
    background: #fff;
    z-index:1;
    margin:0;
}
.react-autosuggest__suggestions-container ul li {
    padding: 5px 7px;
    line-height: 1.5;
}
.react-autosuggest__suggestions-container ul li:hover {
    background-color: #ededed;
    cursor: pointer;
}

/* =============================================================================
 * geosuggest
 * ========================================================================== */
.geosuggest {
    width: 100%;
}
.geosuggest__suggests-wrapper ul {
    list-style: none;
    padding:0;
    margin:0;
    max-height: 220px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid #ccc;
    color: #31535B;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.25);
    position: absolute;
    background: #fff;
    z-index:100;
    margin:0;
}
.geosuggest__suggests-wrapper ul li {
    padding: 5px 7px;
    line-height: 1.5;
}
.geosuggest__suggests-wrapper ul li:hover {
    background-color: #ededed;
    cursor: pointer;
}
.geosuggest__suggests-wrapper ul .geosuggest__item.geosuggest__item--active {
    background-color: #ededed;
    cursor: pointer;
}
.geosuggest .geosuggest__input {
    display: block;
    padding: 8px 5px;
    border-radius: 0;
    border: 2px solid #DAE0E3;
    box-shadow: none;
    -moz-appearance: none;
    appearance: none;
    width:100%;
}
.geosuggest .geosuggest__input:focus {
    border-color: #79AC37;
    outline:0;
}
.geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
    display:none;
}

.geosuggest__suggests:after {
    content: 'powered by Google';
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 5px 7px;
    font-size: 10px;
    margin-top:10px;
    background-color: rgb(245, 245, 245);
}

/* =============================================================================
 * weatherSearchbar
 * ========================================================================== */
.searchbar-cnt {
    /* background: #F7F8F9; */
    margin: 20px 0;
}
.weatherSearchbar h1 {
    margin: 0 0 20px 0;
}
.weatherSearchbar .searchFunctions {
    display: flex;
    align-items:center;
}
.weatherSearchbar .searchFunctions button {
    font-size: 16px;
    padding: 8px;
    background: #79AC37;
    border: 2px solid #79AC37;
    color: #fff;
    outline: 0;
    line-height: 0;
    margin-right:10px;
}
.weatherSearchbar .searchFunctions button:hover {
    cursor: pointer;
}

/* =============================================================================
 * mainmenu
 * ========================================================================== */
.mainmenu {
    padding: 0;
    list-style: none;
    margin: 0;
    display: flex;
}
.mainmenu {
    li {
        margin: 0 10px;
    }
    li a {
        background-color: #31535B;
        color: #fff;
        transition: all .2s;
        padding: 6px 12px;
        border-radius: 2px;
        text-decoration: none;
    }
    li a.active {
        background: #79AC37;
        text-decoration: none;
        color: #fff;
    }
}
@media only screen and (max-width: 991px) {
    .mainmenu {
        display: none;
    }
}

/* =============================================================================
 * mobilemenu
 * ========================================================================== */
.mobilemenu {
    padding: 0;
    list-style: none;
    margin: 0;
    margin-top:40px;
}
.mobilemenu {
    li {
        margin: 10px 0;
    }
    li a {
        color: #31535B;
        transition: all .2s;
        padding: 6px 10px;
        border-radius: 2px;
        text-decoration: none;
        display: block;
        font-size: 18px;
    }
    li a.active {
        color: #79AC37;
        text-decoration: none;
        background: rgba(0,0,0,0.05)
    }
}

/* =============================================================================
 * loadingSpinner
 * ========================================================================== */
.loadSpinner {
    width: 100%;
}
.loadSpinner .loader {
    padding: 30px 0;
    position: relative;
    margin: 0 auto;
    width: 100px;
}
.loadSpinner .loader:before {
    content: '';
    display: block;
    padding-top: 100%;
}
.loadSpinner .circular {
    -webkit-animation: rotate 2s linear infinite;
    animation: rotate 2s linear infinite;
    height: 100%;
    -webkit-transform-origin: center center;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.loadSpinner .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
}

@-webkit-keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
@-webkit-keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
    }
    @keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}
@-webkit-keyframes color {
    100%,
    0% {
        stroke: #79AC37;
    }
    40% {
        stroke: #79AC37;
    }
    66% {
        stroke: #79AC37;
    }
    80%,
    90% {
        stroke: #79AC37;
    }
}
@keyframes color {
    100%,
    0% {
        stroke: #79AC37;
    }
    40% {
        stroke: #79AC37;
    }
    66% {
        stroke: #79AC37;
    }
    80%,
    90% {
        stroke: #79AC37;
    }
}

/* =============================================================================
 * navbar-trigger
 * ========================================================================== */
.navbar-trigger {
    display: none;
}
@media only screen and (max-width: 991px) {
    .navbar-trigger {
        display: flex;
        align-items: center;
        margin-top: 8px;
    }
}
.navbar-trigger:hover {
    cursor: pointer;
}
.navbar-trigger .menu-name {
    margin-right: 10px;
    font-weight: 600;
}
.hamburger {
    position:relative;
    width: 35px;
    height: 25px;
    padding:0;
    background:0 0;
    border:0;
    outline:0;
    margin-top: 10px;
    color:inherit;
    cursor:pointer;
    -webkit-appearance:normal;
    -moz-appearance:normal;
    appearance:normal;
}
@media (max-width:1280px){
    .hamburger {
        font-size:10px;
    }
}
@media (max-width:359px){
    .hamburger {
        font-size:8px;
    }
}
.hamburger span {
    width:30px;
}
.hamburger span,.hamburger span::after,.hamburger span::before {
    display:block;
    position:absolute;
    height:2px;
    right:0;
    background:#10384F;
    transition:width .2s;
    border-radius:5px;
}
.hamburger span::after,.hamburger span::before {
    content:''
}
.hamburger span::before {
    width:20px;
    top:7px;
}
.hamburger span::after {
    width:25px;
    top:14px;
}
.hamburger:hover span::after,.hamburger:hover span::before{
    width:100%
}
/* .close {
padding: 10px;
margin-right: 5px;
} */
.close .line {
    width: 25px;
    border-radius: 5px;
    height: 2px;
    background-color: #10384F;
    display: block;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.navbar-trigger:hover .hamburger {
    cursor: pointer;
    opacity: .7;
}
.navbar-trigger:hover .hamburger span::after, .navbar-trigger:hover .hamburger span::before{
    width:100%
}
.navbar-trigger:hover .menu-name {
    color: #10384F;
}
.close:hover {
    cursor: pointer;
    opacity: .7;
}
.close .line:nth-child(1){
    -webkit-transform: translateY(0px) rotate(45deg);
    -ms-transform: translateY(0px) rotate(45deg);
    -o-transform: translateY(0px) rotate(45deg);
    transform: translateY(0px) rotate(45deg);
}
.close .line:nth-child(2){
    -webkit-transform: translateY(-7px) rotate(-45deg);
    -ms-transform: translateY(-7px) rotate(-45deg);
    -o-transform: translateY(-7px) rotate(-45deg);
    transform: translateY(-7px) rotate(-45deg);
}
.close:hover .line:nth-child(1) {
    -webkit-transform: translateY(0px) rotate(135deg);
    -ms-transform: translateY(0px) rotate(135deg);
    -o-transform: translateY(0px) rotate(135deg);
    transform: translateY(0px) rotate(135deg);
}
.close:hover .line:nth-child(2) {
    -webkit-transform: translateY(-7px) rotate(-135deg);
    -ms-transform: translateY(-7px) rotate(-135deg);
    -o-transform: translateY(-7px) rotate(-135deg);
    transform: translateY(-7px) rotate(-135deg);
}

/* =============================================================================
* page-navigation
* ========================================================================== */
.wrapper.show-nav {
    overflow: hidden;
    min-height: 100%;
}
.wrapper.show-nav .page-navigation-overlay {
    width: 100%;
    height: 100%;
    opacity: 0.4;
}
.wrapper.show-nav .page-navigation {
    transform: translateX(0);
}
.page-navigation-overlay {
    background: #5d6774;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1200;
    opacity: 0;
    transition: opacity 0.3s;
    width: 0;
    height: 0;
}
.page-navigation {
    z-index: 1300;
    position: fixed;
    transition: transform 0.3s;
    transform: translateX(100%);
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    background: #fff;
    background-size: 621px 421px;
    background-position: center bottom;
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
    top: 0;
    right: 0;
 /*    display: flex;
    flex-direction: column;
    align-items: center; */
    
}
/* @media (max-width min-width: 768px) {
    .page-navigation {
        width: 50%;
        min-width: 300px;
        max-width: 350px;
    }
} */
@media only screen and (max-width: 991px) and (min-width: 576px) {
    .page-navigation {
        width: 50%;
        min-width: 300px;
        max-width: 350px;
    }
}
.page-navigation-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

/* =============================================================================
 * navbar mobile
 * ========================================================================== */
.page-navigation .menuTitle {
    color: #bcc1c9;
    margin-top: -8px;
    text-transform: uppercase;
}
/*   .navbar.mobile .mainmenu > li {
    margin:12px 0;
  }
  .navbar.mobile .mainmenu > li > a {
    color:#fff;
  }
  .navbar.mobile .mainmenu > li i {
    color:#fff;
  }
  .navbar.mobile .mainmenu > li > a.active {
    color: #a5b730;
  }
  .navbar.mobile .mainmenu > li.active i {
    color: #a5b730;
  } */

/* =============================================================================
 * hourly72
 * ========================================================================== */
.hourly72 .title {
    text-align: center;
}
.hourly72 .title h2 {
    margin-bottom:0px;
    text-decoration: underline;
    text-decoration-color: #79AC37;
}
.hourly72 .title p {
    font-size: 15px;
    color: rgba(49,83,91,0.5);
    margin-top:0px;
}
.hourly72 h3 {
    color: #31535B;
}
.hourly72 .divider-v1 {
    border-top: 1px solid #bababa;
    display:block;
    width: 100%;
    margin-bottom:20px;
}
.hourly72 .weatherCard {
    border: 1px solid #dae0e3;
}
@media only screen and (min-width: 991px) {
    .hourly72 .activeIndex .weatherCard {
        background-color: rgba(0, 0, 0, 0.025);
    }
    .hourly72 .col-lg-4:not(.activeIndex) .weatherCard:hover {
        cursor: pointer;
    }
    .hourly72 .weatherDayDetails {
        margin-bottom: 40px;
    }
}
.hourly72 .weatherCard .date {
    color: #10384F;
    text-align: center;
    font-size:18px;
    margin: 10px 0;
}
.hourly72 .weatherCard .date .day-name {
    font-weight: bold;
}
.hourly72 .weatherCard .weatherInfo {
    padding: 15px;
    color: #10384F;
    font-weight: bold;
    min-height: 106px;
    font-size: 14px;
}
.hourly72 .weatherCard .weatherDetailBox {
    display: flex;
    align-items: center;
    margin: 10px 0;
}
.hourly72 .weatherCard .weatherDetailBox .descIcon {
    font-size: 20px;
    margin-right: 5px;
    min-width: 25px;
}
.hourly72 .weatherCard .weatherDetailBox .value {
    font-size: 14px;
}
.hourly72 .weatherCard .uppercase {
    text-transform: uppercase;
}
@media only screen and (max-width: 1200px) {
    .hourly72 .weatherCard .weatherInfo {
        font-size: 12px;
    }
    .hourly72 .weatherCard .weatherIcon img {
        width: 60px;
        height: 60px;
    }
    .hourly72 .weatherCard .weatherDetailBox .descIcon {
        font-size: 16px;
        margin-right: 5px;
        min-width: 20px;
    }
}
@media only screen and (max-width: 991px) {
    .hourly72 .weatherCard {
        margin-bottom: 20px;
    }
    .hourly72 .weatherCard .weatherInfo {
        /* padding: 0px;
        margin: 10px 0;
        min-height: auto; */
        display: none !important;
    }
    .hourly72 .weatherCard .date {
        font-size: 14px;
    }
    .hourly72 .weatherCard .weatherIcon {
        margin:10px 0;
        padding-right: 10px;
    }
    .hourly72 .weatherCard .weatherIcon img {
        width: 40px;
        height: 40px;
    }
}
@media only screen and (max-width: 768px) {
    .hourly72 .weatherCard .weatherDetailBox .descIcon {
        display: none;
    }
    .hourly72 .weatherCard .weatherDetailBox .value {
        font-weight: 600;
    }
}
@media only screen and (max-width: 340px) {
    .hourly72 .weatherCard .weatherDetailBox .value {
        font-size: 12px;
    }
}
.hourly72 .weatherTable {
    margin-top:25px;
    color: #10384F;
}
.hourly72 .weatherTable .userInfo {
    margin:25px 0;
    display:flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 13px;
    color: #31535B;
}
.hourly72 .weatherTable .userInfo svg {
    margin-right:5px;
}
.hourly72 .weatherTable .tableHead .titleItem {
    font-size: 16px;
    font-weight: 500;
    height: 30px;
    margin: 20px 0 0;
}
.hourly72 .weatherTable .firstItem {
    font-size: 18px;
    border-bottom: 1px solid #bababa;
    padding: 0 0 15px;
    height: 50px;
}
.hourly72 .weatherTable .tableContent {
    height: 30px;
    margin: 20px 0 0;
}
.hourly72 .weatherTable .icon-btn {
    background: #79AC37;
    color:#fff;
    border:none;
    font-size: 20px;
    padding: 5px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline:0;
}
.hourly72 .weatherTable .icon-btn:hover {
    cursor: pointer;
}
.hourly72 .weatherTable .prevButton {
    position: absolute;
    top: -2px;
    left: -30px;
}
.hourly72 .weatherTable .nextButton {
    position: absolute;
    top: -2px;
    right: -31px;
}
.hourly72 .weatherDayDetails .tableItem {
    text-align: center;
    outline: 0;
}
.hourly72 .weatherDayDetails .tableItem .weatherIcon {
    display: flex;
    align-items: center;
    justify-content: center;
}
.hourly72 .weatherDayDetails .tableItem .weatherIcon img {
    width: 34px;
    height: 34px;
}
@media only screen and (max-width: 1200px) {
    .hourly72 .weatherTable .prevButton {
        top: -2px;
        left: -10px;
    }
    .hourly72 .weatherTable .nextButton {
        top: -2px;
        right: -11px;
    }
}
.hourly72 .mobileDescription {
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}
.hourly72 .mobileDescription .icon {
    margin-right: 10px;
}
.hourly72 .mobileWeatherCards {
    font-size:14px;
    display: none;
    margin-bottom: 25px;
}
.hourly72 .mobileWeatherCards.showDetails {
    display: block;
}

.hourly72 .mobileWeatherCards .weatherPeriod {
    display: flex;
    align-items: center;
    justify-content:space-around;
    margin-bottom:10px;
    padding:4px 0;
}
.hourly72 .mobileWeatherCards .weatherPeriod:nth-child(odd) {
    background: rgba(218, 224, 227, 0.1);
}
.hourly72 .mobileWeatherCards .weatherPeriod .item {
    min-width: 40px;
    display: flex;
    justify-content: center;
}
@media only screen and (max-width: 540px) {
    .hourly72 .mobileWeatherCards {
        font-size: 12px;
    }
}
.hourly72 .mobileWeatherCards .weatherPeriod .item.pointValue {
    min-width: 40px;
}
.hourly72 .mobileWeatherCards .weatherPeriod .weatherIcon {
    min-width: 40px;
    display: flex;
    justify-content:center;
}
.hourly72 .mobileWeatherCards .weatherPeriod .weatherIcon img {
    width: 30px;
    height: 30px;
}
.hourly72 .mobileWeatherCards .weatherPeriod .item .windSymbols {
    display: flex;
    justify-content: center;
    min-width: 50px;
}
.hourly72 .mobileWeatherCards .weatherPeriod .item .windSymbols .descIcon {
    margin-right: 10px;
}
.hourly72 .mobileWeatherCards .weatherPeriod .item .windSymbols .descText {
    text-transform: uppercase;
}
@media only screen and (max-width: 540px) {
    .hourly72 .mobileWeatherCards .weatherPeriod .item .windSymbols .descText {
        display:none;
    }
    .hourly72 .mobileWeatherCards .weatherPeriod .item .windSymbols {
        min-width: 24px;
    }
    .hourly72 .mobileWeatherCards .weatherPeriod .item .windSymbols .descIcon {
        margin-right: 0px;
    }
    .hourly72 .mobileWeatherCards .weatherPeriod .weatherIcon {
        min-width: 28px;
        display: flex;
        justify-content:center;
    }
    .hourly72 .mobileWeatherCards .weatherPeriod .weatherIcon img {
        width: 25px;
        height: 25px;
    }
}
.hourly72 .mobileWeatherCards .weatherPeriodHeader {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: rgba(218, 224, 227,0.5);
    padding:10px 0;
    margin-bottom: 10px;
}
.hourly72 .mobileWeatherCards .weatherPeriodHeader .item {
    min-width: 40px;
    display: flex;
    justify-content:center;
}
.hourly72 .mobileWeatherCards .weatherPeriodHeader .wind {
    min-width: 50px;
    display: flex;
    justify-content:center;
}
.hourly72 .mobileWeatherCards .weatherPeriodHeader .weather {
    min-width: 40px;
}
@media only screen and (max-width: 991px) {
    .hourly72 .col-lg-4 .weatherCard:hover {
        cursor: pointer;
    }
}
.hourly72 .fullDay {
    margin-top: 20px;
}
.hourly72 .fullDay .item {
    margin-bottom: 10px;
}
.hourly72 .fullDay .title {
    font-size: 16px;
    font-weight: 500;
    margin-right: 10px;
    /* color: #31535B; */
}
.hourly72 .fullDay .value {
    color: #222;
    font-size: 16px;
    /* font-weight: 500; */
}
@media only screen and (max-width: 768px) {
    .hourly72 .fullDay .title, .hourly72 .fullDay .value {
        font-size: 14px;
    }
}

/* =============================================================================
 * day6parts
 * ========================================================================== */
.day6parts .title {
    text-align: center;
}
.day6parts .title h2 {
    margin-bottom:0px;
    text-decoration: underline;
    text-decoration-color: #79AC37;
}
.day6parts .title p {
    font-size: 15px;
    color: rgba(49,83,91,0.5);
    margin-top:0px;
}
.day6parts .weatherCard {
    text-align: center;
    /* background: #fff; */
    margin-bottom: 20px;
    /* box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.12), 0 1px 5px 0 rgba(0,0,0,.2);
    border-radius: 2px; */
    border: 1px solid #dae0e3;
}
.day6parts .weatherCard .date {
    color: #10384F;
    margin: 25px 0;
    font-size:15px;
}
@media only screen and (max-width: 768px) {
    .day6parts .weatherCard .date {
        font-size:18px;
        margin: 10px 0;
    }
}
.day6parts .weatherCard .date .day-name {
    font-weight: bold;
}
.day6parts .weatherCard .weatherIcon img {
    width:60px;
    height:60px;
}
@media only screen and (max-width: 768px) {
    .day6parts .weatherCard .weatherIcon img {
        width:50px;
        height:50px;
        margin: 15px 0;
    }
}
.day6parts .weatherCard .weatherInfo {
    padding: 15px;
    color: #10384F;
    margin: 10px 0;
    font-weight: bold;
    min-height: 106px;
    font-size: 14px;
}
@media only screen and (max-width: 768px) {
    .day6parts .weatherCard .weatherInfo {
        padding: 15px;
        color: #10384F;
        font-weight: bold;
        min-height: auto;
        font-size: 14px;
        margin: 0;
    }
}
.day6parts .weatherCard .weatherDetailBox {
    display: flex;
    align-items: center;
    margin: 10px 0;
}
@media only screen and (min-width: 991px) {
    .day6parts .weatherCard .weatherDetailBox.first {
        margin-top:0px;
    }
}
.day6parts .weatherCard .weatherDetailBox .descIcon {
    font-size: 20px;
    margin-right: 10px;
    min-width: 30px;
}

/* =============================================================================
 * daily14
 * ========================================================================== */
.daily14 .title {
    text-align: center;
}
.daily14 .title h2 {
    margin-bottom:0px;
    text-decoration: underline;
    text-decoration-color: #79AC37;
}
.daily14 .title p {
    font-size: 15px;
    color: rgba(49,83,91,0.5);
    margin-top:0px;
}
.daily14 .weatherCard {
    text-align: center;
    margin-bottom: 20px;
    border: 1px solid #dae0e3;
}
.daily14 .weatherCard .date {
    color: #10384F;
    margin: 15px 0;
    font-size:15px;
}
@media only screen and (max-width: 768px) {
    .daily14 .weatherCard .date {
        font-size:18px;
    }
}
.daily14 .weatherCard .date .day-name {
    font-weight: bold;
}
.daily14 .weatherCard .weatherIcon img {
    width:40px;
    height:40px;
}
.daily14 .weatherCard .weatherInfo {
    color: #10384F;
    margin: 10px 0;
    font-weight: bold;
    min-height: 50px;
    font-size: 12px;
    padding: 0 5px;
}
.daily14 .weatherCard .weatherDetailBox {
    display: flex;
    align-items: center;
    margin: 10px 0;
    font-size: 14px;
}
.daily14 .weatherCard .weatherDetailBox .descIcon {
    font-size: 16px;
    margin-right: 4px;
    min-width: 26px;
}
.daily14 .placeholder {
    height: 20px;
}
@media only screen and (max-width: 991px) {
    .daily14 .col-12.hr {
        display:none;
    }
}
@media only screen and (max-width: 991px) and (min-width: 768px) {
    .daily14 .weatherCard .weatherDetailBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
    } 
    .daily14 .weatherCard .weatherDetailBox .descIcon {
        font-size: 16px;
        margin-right: 0px;
        min-width: 26px;
        margin-bottom: 10px;
    }
    .daily14 .weatherCard .weatherDetailBox .value {
        min-height: 40px;
    }
}
@media only screen and (max-width: 768px) {
    .daily14 .weatherCard .weatherInfo {
        padding: 15px;
        min-height: auto;
        font-size: 14px;
        margin: 0;
    }
}

/* =============================================================================
 * significance
 * ========================================================================== */
.extended-title {
    position: relative;
}
.significance {
    position: absolute;
    top: 0;
    right: 0;

    .desc-text {
        font-size: 14px;
        font-weight: bold;
        color: #10384F;
    }
    .green-msg {
        /* color: #270; */
        background-color: #DFF2BF;
        padding: 5px 10px;
        border-radius: 2px;
    }
}

/* =============================================================================
 * weatherChart
 * ========================================================================== */
/* .weatherChart {

} */

/* =============================================================================
 * windrichtung +135 (fa icon)
 * ========================================================================== */
.dd-deg--5 svg {
    transform: rotate(140deg);
}
.dd-deg--10 svg {
    transform: rotate(145deg);
}
.dd-deg--15 svg {
    transform: rotate(150deg);
}
.dd-deg--20 svg {
    transform: rotate(155deg);
}
.dd-deg--25 svg {
    transform: rotate(160deg);
}
.dd-deg--30 svg {
    transform: rotate(165deg);
}
.dd-deg--35 svg {
    transform: rotate(170deg);
}
.dd-deg--40 svg {
    transform: rotate(175deg);
}
.dd-deg--45 svg {
    transform: rotate(180deg);
}
.dd-deg--50 svg {
    transform: rotate(185deg);
}
.dd-deg--55 svg {
    transform: rotate(190deg);
}
.dd-deg--60 svg {
    transform: rotate(195deg);
}
.dd-deg--65 svg {
    transform: rotate(200deg);
}
.dd-deg--70 svg {
    transform: rotate(205deg);
}
.dd-deg--75 svg {
    transform: rotate(210deg);
}
.dd-deg--80 svg {
    transform: rotate(215deg);
}
.dd-deg--85 svg {
    transform: rotate(220deg);
}
.dd-deg--90 svg {
    transform: rotate(225deg);
}
.dd-deg--95 svg {
    transform: rotate(230deg);
}
.dd-deg--100 svg {
    transform: rotate(235deg);
}
.dd-deg--105 svg {
    transform: rotate(240deg);
}
.dd-deg--110 svg {
    transform: rotate(245deg);
}
.dd-deg--115 svg {
    transform: rotate(250deg);
}
.dd-deg--120 svg {
    transform: rotate(255deg);
}
.dd-deg--125 svg {
    transform: rotate(260deg);
}
.dd-deg--130 svg {
    transform: rotate(265deg);
}
.dd-deg--135 svg {
    transform: rotate(270deg);
}
.dd-deg--140 svg {
    transform: rotate(275deg);
}
.dd-deg--145 svg {
    transform: rotate(280deg);
}
.dd-deg--150 svg {
    transform: rotate(285deg);
}
.dd-deg--155 svg {
    transform: rotate(290deg);
}
.dd-deg--160 svg {
    transform: rotate(295deg);
}
.dd-deg--165 svg {
    transform: rotate(300deg);
}
.dd-deg--170 svg {
    transform: rotate(305deg);
}
.dd-deg--175 svg {
    transform: rotate(310deg);
}
.dd-deg--180 svg {
    transform: rotate(315deg);
}
.dd-deg--185 svg {
    transform: rotate(320deg);
}
.dd-deg--190 svg {
    transform: rotate(325deg);
}
.dd-deg--195 svg {
    transform: rotate(330deg);
}
.dd-deg--200 svg {
    transform: rotate(335deg);
}
.dd-deg--205 svg {
    transform: rotate(340deg);
}
.dd-deg--210 svg {
    transform: rotate(345deg);
}
.dd-deg--215 svg {
    transform: rotate(350deg);
}
.dd-deg--220 svg {
    transform: rotate(355deg);
}
.dd-deg--225 svg {
    transform: rotate(360deg);
}
.dd-deg--230 svg {
    transform: rotate(365deg);
}
.dd-deg--235 svg {
    transform: rotate(370deg);
}
.dd-deg--240 svg {
    transform: rotate(375deg);
}
.dd-deg--245 svg {
    transform: rotate(380deg);
}
.dd-deg--250 svg {
    transform: rotate(385deg);
}
.dd-deg--255 svg {
    transform: rotate(390deg);
}
.dd-deg--260 svg {
    transform: rotate(395deg);
}
.dd-deg--265 svg {
    transform: rotate(400deg);
}
.dd-deg--270 svg {
    transform: rotate(405deg);
}
.dd-deg--275 svg {
    transform: rotate(410deg);
}
.dd-deg--280 svg {
    transform: rotate(415deg);
}
.dd-deg--285 svg {
    transform: rotate(420deg);
}
.dd-deg--290 svg {
    transform: rotate(425deg);
}
.dd-deg--295 svg {
    transform: rotate(430deg);
}
.dd-deg--300 svg {
    transform: rotate(435deg);
}
.dd-deg--305 svg {
    transform: rotate(440deg);
}
.dd-deg--310 svg {
    transform: rotate(445deg);
}
.dd-deg--315 svg {
    transform: rotate(450deg);
}
.dd-deg--320 svg {
    transform: rotate(455deg);
}
.dd-deg--325 svg {
    transform: rotate(460deg);
}
.dd-deg--330 svg {
    transform: rotate(465deg);
}
.dd-deg--335 svg {
    transform: rotate(470deg);
}
.dd-deg--340 svg {
    transform: rotate(475deg);
}
.dd-deg--345 svg {
    transform: rotate(480deg);
}
.dd-deg--350 svg {
    transform: rotate(485deg);
}
.dd-deg--355 svg {
    transform: rotate(490deg);
}
.dd-deg--360 svg {
    transform: rotate(495deg);
}

/* =============================================================================
 * footer
 * ========================================================================== */
.app-footer {

    margin-top:60px;
    position: relative;

    .page-footer-top {
        background: #31535B;
        color: #fff;
        padding:40px 0;
    }

    .page-footer-top .logo {
        margin-bottom:30px;
    }
    @media only screen and (max-width: 991px) {
        .page-footer-top .logo {
            width: 200px;
            margin: 30px auto;
        }
    }
    .page-footer-top .cooperation .text {
        margin-bottom: 10px;
    }
    .page-footer-top .cooperation .logo-extern {
        width:200px;
    }

    .page-footer-bottom {
        display: flex;
        justify-content: space-between;
        margin: 30px 0;
    }
    @media only screen and (max-width: 768px) {
        .page-footer-bottom {
            display: block;
            text-align: center;
        }
    }
    .page-footer-bottom .copyright {
        font-weight: 500;
        color:#000;

        a {
            color:#000;
            text-decoration: none;
        }
    }
    .page-footer-bottom .policy-links ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
    }
    .page-footer-bottom .policy-links ul li {
        margin-left:15px;
    }
    .page-footer-bottom .policy-links ul li a {
        color:#000;
        text-decoration: none;
        font-weight: 500;
    }
    .page-footer-bottom .policy-links ul li a:hover {
        text-decoration: underline;
    }
    @media only screen and (max-width: 768px) {
        .page-footer-bottom .policy-links ul {
            justify-content: center;
            margin-top:20px;
        }
        .page-footer-bottom .policy-links ul li {
            margin:0 10px;
        }
    }

    .totop {
        position: absolute;
        left: 50%;
        top: 0;
        margin-left: -20px;
        background: rgba(255,255,255,0.1);
        color: #fff;
        width: 40px; 
        height: 40px;
        border-radius: 0 0 3px 3px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .totop:hover {
        cursor: pointer;
    }
    .totop i {
        font-size: 40px;
    }

    @media only screen and (max-width: 991px) {
        .info {
            text-align: center;
            margin-bottom:50px;
        }
        .page-footer-top .cooperation {
            text-align: center;
        }
        .page-footer-top .cooperation .logo-extern {
            margin: 0 auto;
        }
    }
}